<template>
    <section class="order-prod" :class="{ 'col-tow': orderHeadList.length === 2, 'col-six': orderHeadList.length > 5 }">
        <div class="prod-head">
            <div v-for="item in orderHeadList" :key="item" class="prod-col">{{ item }}</div>
        </div>
        <div class="prod-body">
            <div v-for="item in orderProdList" :key="item.commodity_details_id" class="prod-row">
                <div class="prod-col">
                    <div class="order-prod">
                        <a href="javascript:;" class="order-img">
                            <img :src="item.img" :alt="item.name">
                        </a>
                        <p class="order-txt">
                            <a class="order-txt-item" href="javascript:;">{{ item.title }}</a>
                            <span class="order-txt-item">{{ item.name }}</span>
                        </p>
                    </div>
                </div>
                <div v-if="item.count" class="prod-col">{{ item.count }}</div>
                <div v-if="item.deposit!=='' && item.deposit!==null" class="prod-col">{{ item.type === '2' ? item.deposit : item.money }}</div>
                <!--预定才显示-->
                <div v-if="item.type === '2' && item.supplement!=='' && item.supplement!==null" class="prod-col">{{ item.supplement }}</div>
                <div v-if="item.money!==''&&item.money!==null" class="prod-col">{{ item.moneyall }}</div>
                <div v-if="item.nstatus!==1" class="prod-col">{{ reason[item.nstatus - 2] }}</div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: 'OrderAdress',
    props: {
        orderHeadList: {
            type: Array,
            default: null,
            required: true
        },
        orderProdList: {
            type: Array,
            default: null,
            required: true
        }
    },
    data() {
        return {
            // localOrderHeadList: [],
            reason: ['库存不足', '限购已满', '已截单', '已下架'] // 失效原因
        }
    }
}
</script>
<style lang="scss" scoped>
.prod-head {
    position: relative;
}
.prod-row {
    border-bottom: 1px solid $grayest;
    &:last-child {
        border-bottom: none;
    }
}
.prod-col {
    @include inlineBlock();
    vertical-align: top;
    width: 160px;
    padding: 20px;
    &:first-child {
        width: 500px;
    }
}
.prod-head {
    .prod-col {
        padding: 0 20px;
        font-size: 14px;
        &:first-child {
            padding-left: 0;
        }
    }
}
.order-prod {
    position: relative;
}
.order-img {
    @include inlineBlock();
    margin-right: 20px;
    width: 82px;
    height: 82px;
    border: 1px solid $gray;
    > img {
        display: block;
        width: 100%;
        height: 100%;
    }
}
.order-txt {
    @include inlineBlock();
    vertical-align: top;
    // width: 300px;
}
.order-txt-item {
    display: block;
    color: $light;
    &:first-child {
        margin-bottom: 10px;
        height: 60px;
        overflow: hidden;
        color: $black3;
    }
}
.col-tow {
    .prod-head,
    .prod-body {
        .prod-col:nth-child(1) {
            width: calc(100% - 180px);
        }
    }
}
.col-six {
    .prod-head,
    .prod-body {
        .prod-col{
            width: 150px;
            &:nth-child(1) {
                width: 400px;
            }
        }
    }
}
.order-invalid {
    .prod-body {
        color: $light;
    }
    .order-txt-item {
        color: $light;
    }
}
</style>

<template>
    <section class="order-adress" :class="{ collapse: !showMore }">
        <div class="order-adress-head">
            <div class="order-adress-tit">收货人信息</div>
            <div v-if="addressList[0]" class="order-adress-toolbar">
                <router-link to="/my/address" class="order-link red">新增收货地址</router-link>
            </div>
        </div>
        <div class="adress-body">
            <ul v-if="addressList[0]" class="adress-list">
                <li v-for="item in addressList" :key="item.user_address_id" class="adress-item" :class="{ active: item.user_address_id===userAddressId }">
                    <a href="javascript:;" class="adress-name" @click="setAddress(item)">
                        <span class="address-name-item">{{ item.name }}</span>
                        <span class="address-name-item">{{ item.province }}</span>
                    </a>
                    <span class="text-ellipsis adress-all" :title="item.name+item.province+item.city+item.address+item.mobile.slice(0, 3) + '****' + item.mobile.slice(7, 11)">
                        <span class="address-name-item">{{ item.name }}</span>
                        <span class="address-name-item">{{ item.province }}</span>
                        <span class="address-name-item">{{ item.city }}</span>
                        <span class="address-name-item">{{ item.country }}</span>
                        <span class="address-name-item">{{ item.address }}</span>
                        <span class="address-name-item">{{ item.mobile.slice(0, 3) + '****' + item.mobile.slice(7, 11) }}</span>
                    </span>
                </li>
            </ul>
            <div v-else class="nodata">
                <p>尚未设置收货地址</p>
                <p><router-link to="/my/address" class="btn-primary btn-round">新增收货地址</router-link></p>
            </div>
        </div>
        <div v-if="addressList.length >= 2" class="adress-foot" @click="toggleMore">
            {{ showMore ? '收起地址' : '展开地址' }} <i class="triangle" />
        </div>
    </section>
</template>
<script>
export default {
    name: 'OrderAdress',
    props: {
        addressList: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            showMore: true,
            userAddressId: ''
        }
    },
    watch: {
        addressList(val) {
            if (val && val[0]) {
                const defaultAddress = val.find(item => item.isdefault === '1')
                // 有默认地址则选中默认地址，否则选中第一条
                this.userAddressId = defaultAddress ? defaultAddress.user_address_id : val[0].user_address_id
                this.$emit('getFare', defaultAddress)
            }
        }
    },
    methods: {
        setAddress(address) {
            if(!address) return
            this.userAddressId = address.user_address_id
            this.$emit('getFare', address)
        },
        toggleMore() {
            this.showMore = !this.showMore
            this.$emit('showMoreAddress', this.showMore)
        }
    }
}
</script>
<style lang="scss" scoped>
.triangle {
    @include inlineBlock();
    @include triangle('top', 5px, 8px, $dark);
}
.order-adress-head {
    position: relative;
    margin-bottom: 20px;
}
.order-adress-tit {
    font-size: 14px;
}
.order-adress-toolbar {
    position: absolute;
    top: 0;
    right: 0;
}
.adress-list {
    padding: 0 20px;
    max-height: 140px;
    overflow: auto;
    transition: all .3s;
}
.adress-item {
    margin-bottom: 10px;
    color: $dark;
}
.adress-name {
    @include inlineBlock();
    @include lineHeight(27px);
    width: 140px;
    padding: 0 10px;
    margin-right: 10px;
    border: 1px solid $gray;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
    color: $light;
}
.address-name-item {
    padding: 0 2px;
}
.adress-item.active {
    .adress-name {
        border: 1px solid $red;
        color: $red;
        background-color: $grayest;
    }
}
.adress-foot {
    padding: 0 20px;
    margin-top: 20px;
    cursor: pointer;
    .triangle {
        vertical-align: 0;
    }
    &:hover {
        color: $red;
        .triangle {
            @include triangle('top', 5px, 8px, $reder);
        }
    }
}
.collapse {
    .adress-list {
        height: 30px;
        overflow: hidden;
    }
    .adress-foot {
        .triangle {
            vertical-align: -1px;
            @include triangle('right', 8px, 5px, $dark);
        }
        &:hover {
            .triangle {
                @include triangle('right', 8px, 5px, $reder);
            }
        }
    }
}
.adress-all {
    display: inline-block;
    vertical-align: middle;
    width: 950px;
    height: 27px;
    line-height: 27px;
    overflow: hidden;
}
.nodata {
    padding: 30px 0;
    text-align: center;
    p {
        margin-bottom: 10px;
        color: $light;
    }
}
</style>

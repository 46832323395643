<template>
    <div ref="order" class="main order ft-fixed">
        <simple-header :userInfo="userInfo" />
        <order-logo />
        <order-adress class="order-item mt20" :address-list="addressList" @getFare="getFare" @setAddrss="selectedAddressId=$event" @showMoreAddress="addressDrop=$event" />
        <order-prod-list v-if="validProdList[0]" class="order-item" :order-head-list="validOrderHeadList" :order-prod-list="validProdList" />
        <order-prod-list v-if="invalidProdList[0]" class="order-item order-invalid mt20" :order-head-list="invalidOrderHeadList" :order-prod-list="invalidProdList" />
        <!-- 无有效商品时不显示 START -->
        <order-price class="order-item order-price" :price-obj="priceObj" />
        <div class="btn-wrapper">
            <a href="javascript:;" class="btn-primary btn-round" :class="{ 'btn-disabled': !validProdList[0] }" @click="addOrder">提交订单</a>
        </div>
        <!-- 无有效商品时不显示 END -->
        <simple-footer />
    </div>
</template>
<script>
import simpleHeader from '@c/simpleHeader.vue'
import simpleFooter from '@c/simpleFooter.vue'
import orderLogo from './orderLogo.vue'
import orderAdress from './orderAdress.vue'
import orderProdList from './orderProdList.vue'
import orderPrice from './orderPrice.vue'
import mixin from '@c/mixins'
import authMixin from '@c/mixins/auth'
import { getOrderInfo, getFare, addOrder } from '@api/order'
export default {
    name: 'Order',
    mixins: [ mixin, authMixin ],
    components: {
        simpleHeader,
        simpleFooter,
        orderLogo,
        orderAdress,
        orderProdList,
        orderPrice
    },
    data() {
        return {
            buyData: [], // 购买的商品列表
            validOrderHeadList: ['商品', '数量', '定金（元）', '尾款（元）', '总金额（元）'],
            validProdList: [],
            invalidOrderHeadList: ['失效商品', '数量', '定金（元）', '尾款（元）', '总金额（元）', '失效原因'],
            invalidProdList: [],
            commodityDetailsId: '',
            priceObj: {
                orderPrice: '',
                faremoney: 0,
                // c: '',
                total: ''
            },
            addressList: [],
            selectedAddressId: '',
            addressDrop: true
        }
    },
    methods: {
        async getOrderInfo(prodInfo) {
            console.log('prodInfo', prodInfo)
            const res = await getOrderInfo({ info: JSON.stringify(prodInfo) })
            console.log('res', res)
            if (res && res.code === 1001) {
                // nstatus不等于1为失效商品
                this.priceObj.orderPrice = res.detail.money
                const commodityArr = res.detail.commodity_arr
                const product = commodityArr[0]
                // 现货头部不一样单独写(定金改为定价，没有尾款)
                if(product && product.type === '1') {
                    this.validOrderHeadList = ['商品', '数量', '定价（元）', '总金额（元）']
                    this.invalidOrderHeadList = ['失效商品', '数量', '定价（元）', '总金额（元）', '失效原因']
                }
                if(product) {
                    this.commodityDetailsId = product.commodity_details_id
                }
                this.validProdList = product ? commodityArr.filter(item => item.nstatus === 1) : []
                // console.log('validProdList', this.validProdList)
                this.invalidProdList = product ? commodityArr.filter(item => item.nstatus !== 1) : []
                this.addressList = res.detail.addressList
                // if (this.addressList[0]) {
                //     const defaultAddress = this.addressList.find(item => item.isdefault === '1')
                //     // 有默认地址则选中默认地址，否则选中第一条
                //     const address = defaultAddress || this.addressList[0]
                //     console.log('address', address)
                //     this.getFare(address)
                // } else {
                //     // 没有地址时，总价等于商品价格，不用加运费
                //     this.priceObj.faremoney = '0.00'
                //     this.priceObj.total = this.priceObj.orderPrice
                // }
                // 没有地址时，总价等于商品价格，不用加运费
                if (!this.addressList[0]) {
                    this.priceObj.faremoney = '0.00'
                    this.priceObj.total = this.priceObj.orderPrice
                }
            }
        },
        // 获取运费
        async getFare(address) {
            if(!address) {
                return
            }
            // 获取当前地址ID
            this.selectedAddressId = address.user_address_id
            // 必须有有效商品
            // if (!this.validProdList[0]) return
            const params = {
                users_address_id: address.user_address_id,
                commodity_details_id: this.commodityDetailsId
            }
            const res = await getFare(params)
            console.log('res', res)
            this.priceObj.faremoney = res.detail.faremoney
            this.priceObj.total = (+this.priceObj.orderPrice + +this.priceObj.faremoney).toFixed(2)
        },
        // 提交订单
        async addOrder() {
            // 必须有有效商品
            if (!this.validProdList[0]) {
                // this.$notify({ title: '提示', type: 'warning', message: '无有效商品，请重新购买下单', position: 'bottom-right' })
                return
            }
            // 必须有有效地址
            if (!this.selectedAddressId) {
                // this.$notify({ title: '提示', type: 'warning', message: '请选择收货地址', position: 'bottom-right' })
                return
            }
            const orderProdList = this.validProdList.map(item => {
                return { 'commodity_details_id': item.commodity_details_id, num: item.count }
            })
            console.log('orderProdList', orderProdList)
            const params = {
                users_address_id: this.selectedAddressId,
                info: JSON.stringify(orderProdList)
                // num: 1
            }
            const res = await addOrder(params)
            console.log('res', res)
            // detail.status === 5 该地区不卖
            const resDetail = res.detail
            if (resDetail && resDetail.status === 5) {
                this.$notify({ title: '提示', type: 'error', message: '该地区不卖，请更换收货地址', position: 'bottom-right' })
            }
            if (resDetail && resDetail.orderid[0]) {
                this.$router.push({ path: '/payment', query: { orderid: JSON.stringify(resDetail.orderid), money: this.priceObj.total }})
            }
        }
    },
    created() {
        this.getAccount()
    },
    beforeRouteEnter(to, from, next) {
        console.log('from, to', from, to)
        next(vm => {
            console.log('vm.$route.query', vm.$route.query)
            const buyDataStr = vm.$route.query['buy-data']
            if (buyDataStr) {
                vm.buyData = []
                try {
                    vm.buyData = JSON.parse(buyDataStr)
                } catch (err) {
                    console.log('err', err)
                }
                if (vm.buyData[0]) {
                    vm.getOrderInfo(vm.buyData)
                } else {
                    vm.$notify({ title: '提示', type: 'error', message: '未识别到商品，请重新购买下单', position: 'bottom-right' })
                    vm.$router.push(from.path)
                }
            }
        })
    }
}
</script>
<style lang="scss" scoped>
.order-logo {
    position: relative;
    height: auto;
    padding-bottom: 0;
}
.order-item {
    position: relative;
    width: 1200px;
    min-height: 120px;
    padding: 20px;
    margin: 10px auto;
    border: none;
    background-color: $white;
    &.mt20 {
        margin-top: 20px;
    }
}
.btn-wrapper {
    width: 1200px;
    margin: 10px auto 20px;
    text-align: right;
    .btn-primary {
        width: 200px;
    }
}
.order {
    position: relative;
    // min-height: 950px;
    padding-bottom: 105px;
    min-height: 950px;
}
// .footer {
//     position: absolute;
//     width: 100%;
//     bottom: 0;
//     left: 0;
// }
.ft-fixed {
    .footer {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
    }
}
</style>
